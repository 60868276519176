.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

a {
  color: rgb(124, 124, 124);
}

a:hover {
  color: rgb(31, 31, 31);
}