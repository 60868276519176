.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home div {
  position:relative;
}
  
.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home h4, h5 {
  text-align: left;
  margin-top: 20px;
}

.members h3 {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 40px;
}

.LoaderButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.spinner {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}